.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .container {
    margin: 30px 0px 15px 0px;
    flex: 1 1 auto;
    text-align: left;
  }

}