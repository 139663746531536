.modal .modal-dialog.fullscreen {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0;

  .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
  }
}
