@import "~bootstrap/scss/bootstrap";
@import "bootstrap_overrides";
@import "_variables";
@import "./layout/BaseLayout";
@import "./layout/Header";
@import "./layout/Navigation";
@import "./layout/Content";

@import "views/Login.scss";
@import "views/Profile.scss";
@import "views/UserAdministration.scss";
@import "views/SaisonManagement";
@import "views/EventManagement";

@import "component/EventList.scss";
@import "component/ProfileForm.scss";
@import "component/Scores.scss";
@import "component/List";
@import "component/AssignEvent";

h1 {
  font-size: 2.4rem;
  margin-top: 2.8rem;
}

h2 {
  font-size: 1.9rem;
  margin-top: 2rem;
}

h3 {
  font-size: 1.4rem;
  margin-top: 1.4rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
