// Fonts
// --------------------------------------------
$font-family-base           : Helvetica, Arial, sans-serif;
$font-size-base             : 0.875rem;
$font-line-height-base      : 1.5;
$font-color-base            : #fff;
$font-semibold              : 600;
$font-weight-bold           : 700;
$font-weight-medium         : 500;
$font-weight-regular        : 400;
$font-weight-light          : 300;

// --------------------------------------------
$primary_color: #c7241f;
$primary_color_hover: #6e0d09;
$primary_color_active: #9e0904;
$secondary_color: #0099ff;

$transition_time: 0.2s;

$sidebar-width: 250px;