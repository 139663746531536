.point-badges-wrapper {

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;

  div {
    margin: 5px 0 5px 0;
  }

  h3 {
    padding-top: 0;
    margin-top: 0;
    display: inline-block;
  }

}

.point-badges-header-wrapper {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;

  div {
    margin: 5px 0 3px 0;
  }

  h3 {
    padding-top: 0;
    margin-top: 0;
    display: inline-block;
  }

  .point-badge-header-item {
    padding-right: 10px;
  }

  @media (max-width: 1024px) {

    div {
      margin: 3px 0 3px 0;
    }

    h3 {
      font-size: medium;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0.3em;
      display: inline-block;
    }

    .point-badge-header-item {
      padding-right: 3px;
    }
  }
}

.point-progress-wrapper {
  margin: 20px 0 40px 0;

  .point-progress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-content: center;
  }
}