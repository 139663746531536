.UserAdministration {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .container {
    flex: 1 1 auto;
    text-align: left;
  }
}
