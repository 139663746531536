.eventlist-container.isMobileOnly .MuiTableRow-root {
  clear: both;

  .MuiTableCell-root {
    float: left;
    width: 50%;
    border: none;

    &:last-child {
      width: 100%;
      padding-top: 0;
    }
  }
}