.sidebar {
  padding: 10px;
  text-align: left;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: $sidebar-width;
  background: $primary_color;
  color: $font-color-base;
  margin-left: -$sidebar-width;
  transition: all $transition_time;
  position: absolute;
  z-index: 300;
  height: calc(100vh - 50px);

  @media only screen and (min-width: 768px) {
    /* For desktop: */
    position: relative;
    z-index: 0;
    height: 100%;
  }

  &.is-open {
    margin-left: 0;
    transition: $transition_time;
  }
}

.nav {
  &-item {

    &:hover {
      background: $primary_color_hover;
    }

    &.active {
      background: $primary_color_active;
    }
    
    &:last-child {
      position: absolute;
      bottom: 15px;
      width: 90%;
    }
  }

  &-link {
    color: $font-color-base;
    font-size: $font-size-base;

    &:hover {     
      color: $font-color-base;
    }
  }
}

.side-menu {
  overflow-y: scroll;
  
  &-title {
    padding: 15px;
    font-size: 1rem;
  }

  &-divider {
    width: 100%;
    border-color: $font-color-base;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary_color_active;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $primary_color_hover;
  }
}
