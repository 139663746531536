.login {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .container {
    flex: 1 1 auto;
  }

  .container img {
    margin: 12.5vh 0 10vh 0;
    width: 20vh;
  }

  .card {
    border-color: #aaa;
  }

  form {
    text-align: left;
  }

  #submit {
    margin-top: 15px;
    background-color: $primary_color;
    border-color: $primary_color;
    font-weight: $font-weight-bold;
  }

  .btn-link {
    margin-top: 25px;
    color: $primary_color;
  }

}