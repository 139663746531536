.header-container {
  display: flex;
  height: 50px;
  justify-content: space-around;
  background-color: $primary_color;
  color: #fff;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
}

.header-title {
  text-align: center;
}

.header-points {
  position: absolute;
  right: 15px;
}